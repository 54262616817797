export const PROFILE_TAGS = [
    "@Code 👨‍💻",
    "@Anime 😌",
    "@Gym 💪",
    "@Always on 🤓",
    "@gamer 🎲",
    "@Open-Sourcerer🧙‍♂️",
];

export const X_USERNAME = "user64bit";
export const GITHUB_USERNAME = "user-64bit";
export const DISPLAYNAME = "User64bit";
export const GITHUB_QUOTE = "Debugging";
export const ABOUT_YOU = `Debugging my code and life simultaneously..`;
